import React, { Component } from 'react'
import AppBar from "./components/AppBar/AppBar"
import Chart1 from "./components/Chart1/Chart1"
import Chart2 from "./components/Chart2/Chart2"

export default class App extends Component {
  render() {
    return (
      <div>
        <AppBar></AppBar>
        <Chart1></Chart1>
        <Chart2></Chart2>
      </div>
    )
  }
}
