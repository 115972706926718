import { createMuiTheme } from "@material-ui/core/styles";

// Create a theme instance.
const myTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#DADFE1"
    },
    secondary: {
      main: "#33b286"
    },

  }
})



const myTheme1 = createMuiTheme({
  palette: {
    primary: {
      main: "#1FDA9A"
    },
    secondary: {
      main: "#0069d9"
    }
  },
  typography: {
    fontFamily: "Comic Sans MS",
    body2: {
      fontFamily: "Times New Roman",
      fontSize: "1.1rem"
    }
  },
  shape: {
    borderRadius: 2
  },
  spacing: 2,
//--------------

  overrides: {
    MuiToolbar:{
        root:{
            margin:0,
        }
    }
//--------------
  },
  props: {
    MuiButton: {
      disableRipple: true,
      variant: "contained",
      color: "primary"
    },
    MuiCheckbox: {
      disableRipple: true
    },
    MuiTextField: {
      variant: "filled",
      InputLabelProps: {
        shrink: true
      }
    },
    MuiPaper: {
      elevation: 12
    },
    MuiCard: {
      elevation: 2
    },
  }
});

export default myTheme




/*
const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "green"
        },
        "&:hover $notchedOutline": {
          borderColor: "red"
        },
        "&$focused $notchedOutline": {
          borderColor: "purple"
        },
        "&&& $input": {
          padding: "1px"
        }
      }
    }
  }
});
*/