import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
export default function SimpleMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button onClick={handleClick} style={{boxShadow:"none",backgroundColor:"#E0E3E6",width:"100px",padding:"5px 8px"}}>Show
            <ArrowDropDownIcon></ArrowDropDownIcon>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={true}
                                onChange={() => alert("hello")}
                                name="checkedB"
                                color="secondary"
                            />
                        }
                        label="Primary but not se"
                    />
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={true}
                                onChange={() => alert("hello")}
                                name="checkedB"
                                color="secondary"
                            />
                        }
                        label="Primary"
                    />
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={true}
                                onChange={() => alert("hello")}
                                name="checkedB"
                                color="secondary"
                            />
                        }
                        label="Primary"
                    />
                </MenuItem>
            </Menu>
        </div>
    );
}
