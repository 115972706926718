import React, { Component } from 'react'

export default class Chart1 extends Component {
    render() {
        return (
            <div style={{ height: "500px", backgroundColor: "#D8DDDF", marginTop: 0 }}>
                <div style={{ height: "30px", backgroundColor: "#D1D6D8" }}>
                </div>
            </div>
        )
    }
}
